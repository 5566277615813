<template>
  <section
    ref="sideBySide"
    class="side-by-side"
  >
    <div class="side-by-side__heading">
      <cdr-img
        alt="Illustration of a mountain and a forest tree"
        src="https://www.rei.com/dam/20091024_side-by-side-forest.svg"
      />
      <h2 data-ui="side-by-side-member-mastercard-title">
        Just for members, the REI Co-op<sup>®</sup>
        Mastercard<sup>®</sup> makes membership go further
      </h2>
    </div>

    <div class="side-by-side__content">
      <cdr-table>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>REI Co-op Membership</th>
            <th>REI Co-op Mastercard</th>
          </tr>
        </thead>
        <tbody data-ui="side-by-side-benefits-list">
          <tr
            v-for="(row, rowIndex) in tableData"
            :key="rowIndex"
            :data-ui="`side-by-side-benefit-${rowIndex}`"
          >
            <th :colspan="currentColspan">
              <strong
                v-if="row.heading"
                class="heading"
                v-html="row.heading"
              />
              <div
                v-if="row.subheading"
              >
                <span
                  class="subheading"
                  v-html="row.subheading"
                />
                <cdr-link
                  v-if="row.subheadingLinkText"
                  target="_blank"
                  :href="row.subheadingLinkUrl"
                  :data-ui="row.subheadingLinkDataUI"
                >
                  {{ row.subheadingLinkText }}
                </cdr-link>
              </div>
              <div class="mobile-info">
                <div
                  v-for="(col, colIndex) in row.columns"
                  :key="colIndex"
                >
                  <icon-check-fill
                    v-if="col.included"
                    class="check"
                  />
                  <icon-x-stroke
                    v-if="col.included === false"
                    class="x-stroke"
                  />
                  <span
                    v-if="col.description"
                    class="description"
                    v-html="col.description"
                  />
                </div>
              </div>
            </th>
            <td
              v-for="(col, colIndex) in row.columns"
              :key="colIndex"
            >
              <icon-check-fill
                v-if="col.included"
                class="check"
              />
              <icon-x-stroke
                v-if="col.included === false"
                class="x-stroke"
              />
              <span
                v-if="col.description"
                class="description"
                v-html="col.description"
              />
            </td>
          </tr>
        </tbody>
      </cdr-table>
    </div>
  </section>
</template>

<script setup>
import {
  ref, computed, onMounted, onUnmounted,
} from 'vue';
import {
  CdrImg,
  CdrTable,
  CdrLink,
  IconCheckFill,
  IconXStroke,
} from '@rei/cedar';
import { CdrBreakpointSm } from '@rei/cdr-tokens';
import { metricsLink } from '../../../utils/analytics';
import sideBySideBenefits from '../../../mocks/sideBySideBenefits.json';

const sideBySide = ref();
const currentColspan = ref(1);
const isExperienceReached = ref(false);

const tableData = computed(() => sideBySideBenefits);

/*
 * Methods
 */
const onResize = () => {
  if (typeof window !== 'undefined' && window.document) {
    currentColspan.value = window.innerWidth < CdrBreakpointSm ? 2 : 1;
  }
};

const onScroll = () => {
  if (typeof window !== 'undefined' && window.document) {
    const sideBySidePosition = sideBySide.value.getBoundingClientRect();

    if (window.innerHeight / 2 >= sideBySidePosition.top && !isExperienceReached.value) {
      isExperienceReached.value = true;
      metricsLink('membership:mastercard_sidebyside');
    }
  }
};

onMounted(() => {
  if (typeof window !== 'undefined') {
    window.addEventListener('resize', onResize);
    window.addEventListener('scroll', onScroll);

    onResize();
    onScroll();
  }
});

onUnmounted(() => {
  if (typeof window !== 'undefined') {
    window.removeEventListener('resize', onResize);
    window.removeEventListener('scroll', onScroll);
  }
});
</script>

<style lang="scss">
.side-by-side {
  @include cdr-container;
  margin-top: $cdr-space-one-x;
  margin-bottom: $cdr-space-one-x;

  @include cdr-sm-mq-up {
    margin-top: $cdr-space-three-x;
    margin-bottom: $cdr-space-two-x;
  }
}

.side-by-side__heading {
  width: 100%;
  max-width: 66.3rem;
  margin: 0 auto $cdr-space-two-x;
  text-align: center;

  @include cdr-sm-mq-up {
    display: flex;
    align-items: center;
    text-align: left;
  }

  img {
    width: 9rem;
    height: auto;

    @include cdr-sm-mq-up {
      width: 12.2rem;
      margin-right: $cdr-space-half-x;
    }
  }

  h2 {
    @include cdr-text-heading-sans-400;
    max-width: 30rem;
    margin: 0 auto;
    color: $cdr-color-background-brand-spruce;
    text-wrap: initial;

    @include cdr-sm-mq-up {
      @include cdr-text-heading-sans-600;
      max-width: 100%;
    }
  }

  sup {
    font-size: 1.2rem;
    line-height: 0;
    font-weight: 600;
  }
}

.side-by-side__content {
  max-width: 96.8rem;
  margin: 0 auto;
  border-bottom: 1px solid $cdr-color-border-primary;

  &, table {
    width: 100%;
  }

  table {
    border-spacing: 0;
  }

  thead {
    th {
      @include cdr-text-heading-sans-400 ;
      width: 50%;
      padding: $cdr-space-one-x;
      padding-top: 0;
      color: $cdr-color-text-emphasis;

      @include cdr-sm-mq-up {
        width: 20.2rem;
      }

      &:first-child {
        display: none;
        width: auto;

        @include cdr-sm-mq-up {
          display: table-cell;
        }
      }
    }
  }

  tbody {
    th, td {
      border-top: 1px solid $cdr-color-border-primary;
    }

    th {
      @include cdr-text-utility-sans-200;
      padding: $cdr-space-one-x 0;
      text-align: center;

      @include cdr-sm-mq-up {
        text-align: left;
      }
    }

    td {
      display: none;
      padding: $cdr-space-one-x $cdr-space-half-x;
      text-align: center;

      @include cdr-sm-mq-up {
        display: table-cell;
      }

      &:nth-child(3) {
        background: rgba(#BFB33E, 0.3);
      }
    }

    .mobile-info {
      display: flex;
      margin-top: $cdr-space-half-x;

      > div {
        flex: 1 0 50%;
      }

      @include cdr-sm-mq-up {
        display: none;
      }
    }

    .heading {
      @include cdr-text-utility-sans-strong-300;

      sup {
        font-size: 1rem;
        line-height: 0;
      }
    }

    .check {
      fill: $cdr-color-border-success;
    }

    .x-stroke {
      fill: $cdr-color-icon-disabled
    }

    .description {
      @include cdr-text-utility-sans-300;

      sup {
        font-size: 1rem;
        line-height: 0;
      }
    }
  }
}
</style>
