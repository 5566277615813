<template>
  <div data-ui="mastercard">
    <fauxmo-message-banner
      v-if="showFauxmoBanner"
      :is-limited-time-offer-active="showLimitedTimeOffer"
    />
    <lead-component v-bind="leadModel" />
    <section
      v-if="showRewardsCalculator"
      class="mastercard__rwd-calc"
    >
      <div class="mastercard__rwd-calc-inner">
        <rewards-calculator v-bind="rewardsCalculatorModel" />
      </div>
    </section>
    <side-by-side-benefits
      v-if="showSideBySideBenefits"
    />
    <mastercard-sticky-cta
      v-bind="pageModel?.stickyCta"
      :is-visible="showStickyCta"
    />
    <div class="mastercard">
      <card-carousel
        v-bind="pageModel?.carousel"
        :is-small="isSmall"
      />
      <third-tier-benefits-list
        v-bind="allBenefits"
        :is-small="isSmall"
        class="mastercard__third-tier"
      />
    </div>
    <div class="mastercard">
      <just-for-members
        v-bind="justForMembersContent"
      />
      <benefits-cta v-bind="pageModel?.benefitsCta" />
    </div>
    <div class="mastercard__lower">
      <div class="mastercard__lower-inner">
        <giftcard-promo
          v-if="pageModel?.footer?.giftcardPromo"
          v-bind="pageModel?.footer?.giftcardPromo"
        />
        <terms-conditions v-bind="termsConditionsModel" />
        <capital-one-footer v-bind="pageModel?.footer?.capOneFooter" />
      </div>
    </div>
  </div>
</template>

<script>
import { getUser } from '@rei/session';
import { CdrBreakpointMd } from '@rei/cdr-tokens';
import analyticsMixin from '../../common/mixins/analyticsMixin';
import buildStickyCtaScrollHandler from '../shared/utils/buildStickyCtaScrollHandler';
import BenefitsCta from '../../common/components/benefits-cta/BenefitsCta.vue';
import CapitalOneFooter from '../../common/components/capital-one-footer/CapitalOneFooter.vue';
import RewardsCalculator from './components/RewardsCalculator.vue';
import SideBySideBenefits from './components/SideBySideBenefits.vue';
import CardCarousel from './components/CardCarousel.vue';
import FauxmoMessageBanner from './components/FauxmoMessageBanner.vue';
import GiftcardPromo from '../../common/components/giftcard-promo-component/GiftcardPromo.vue';
import LeadComponent from './components/LeadComponent.vue';
import MastercardStickyCta from '../shared/components/MastercardStickyCta.vue';
import JustForMembers from './components/JustForMembers.vue';
import TermsConditions from '../../common/components/terms-conditions-component/TermsConditions.vue';
import ThirdTierBenefitsList from './components/ThirdTierBenefitsList.vue';
import { getCustomerAttributes, getPersonalizedMemberRewardInfo } from '../../utils/user-account-data';
import { getRewardsCalculatorModel } from '../../utils/mastercard/mastercard-model-adapter';
import { getTermsConditionsModel } from '../../utils/shared-adapters';
import AdobeTarget from '../../utils/adobe-target';

export default {
  name: 'MastercardPage',
  components: {
    BenefitsCta,
    CapitalOneFooter,
    CardCarousel,
    FauxmoMessageBanner,
    GiftcardPromo,
    JustForMembers,
    LeadComponent,
    MastercardStickyCta,
    RewardsCalculator,
    SideBySideBenefits,
    TermsConditions,
    ThirdTierBenefitsList,
  },
  mixins: [
    analyticsMixin,
  ],
  props: {
    /* c8 ignore start */
    additionalData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    /* c8 ignore end */
    componentModels: {
      type: Object,
      required: true,
    },
    appProps: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data: () => ({
    screenWidth: 0, // mobile-first, baby
    isMembershipConfirmed: false,
    showStickyCta: false,
    scrollHandler: null,
    trackingId: null,
    isCardHolder: false,
    personalizedTopCategory: undefined,
    isUserLoggedIn: false,
    sideBySideTestVariant: 'control',
  }),
  computed: {
    allBenefits() {
      return { benefits: this.pageModel?.benefits || [] };
    },
    analyticsViewData() {
      const { trackingId } = this;
      return {
        templateType: 'mastercard',
        pageSection: 'landing',
        trackingId,
      };
    },
    leadModel() {
      const {
        isCardHolder,
        isUserLoggedIn,
        frontendStrings,
        pageModel,
        personalizedTopCategory,
        showDoubleRewardsPromo: isDoubleRewardsActive,
      } = this;

      return {
        ...frontendStrings,
        ...pageModel?.lead,
        isCardHolder,
        isDoubleRewardsActive,
        isUserLoggedIn,
        personalizedTopCategory,
      };
    },
    featureToggles() {
      return this.appProps?.['feature.toggles'] || {};
    },
    frontendStrings() {
      return this.appProps?.['frontend.strings'] || {};
    },
    isCobrandPreApprovalActive() {
      return !!this.appProps['cobrand.preapproval.enabled'];
    },
    isCAASEnabled() {
      return this.appProps?.['cobrand.getCustomerAttributes.enabled'];
    },
    isSmall() {
      const breakpointMd = parseFloat(CdrBreakpointMd);
      return this.screenWidth < breakpointMd;
    },
    justForMembersContent() {
      const isMember = this.isMembershipConfirmed ? 'MEMBER' : 'NON_MEMBER';
      return this.pageModel?.justForMembers[isMember];
    },
    pageModel() {
      return this.componentModels.pageData || {};
    },
    rewardsCalculatorModel() {
      const { frontendStrings, pageModel: { rewardCalculator: cmsModel } } = this;
      return getRewardsCalculatorModel({ frontendStrings, cmsModel });
    },
    showRewardsCalculator() {
      return !!this.pageModel?.rewardCalculator;
    },
    showDoubleRewardsPromo() {
      return this.featureToggles?.showDoubleRewardsPromo;
    },
    termsConditionsModel() {
      const {
        frontendStrings,
        showLimitedTimeOffer,
      } = this;

      return getTermsConditionsModel({
        ...this.pageModel?.footer?.terms,
        isLimitedTimeOfferActive: showLimitedTimeOffer,
        limitedTimeOfferDisclaimer: frontendStrings?.limitedTimeOfferDisclaimer,
      });
    },
    showSideBySideBenefits() {
      return this.sideBySideTestVariant === 'B';
    },
    showLimitedTimeOffer() {
      return this.featureToggles?.showLimitedTimeOffer;
    },
    showFauxmoBanner() {
      return this.showDoubleRewardsPromo || this.showLimitedTimeOffer;
    },
  },
  created() {
    if (typeof window !== 'undefined') {
      if (window.reiSession) {
        /** Method provided by @rei/session library */
        getUser()
          .then((data) => {
            this.isMembershipConfirmed = !!data?.membership?.memberNumber;
            this.isUserLoggedIn = !!data?.isLoggedIn;
            if (this.isUserLoggedIn && !this.showDoubleRewardsPromo) {
              this.getPersonalizedTopCategory();
              this.getIsReiCardHolder();
            }
          })
          .catch((e) => console.error(e));
      }

      if (window.location) {
        const urlParams = new URLSearchParams(window.location.search);
        const trackingId = urlParams.get('trackingId');
        const paidMediaTrackingId = urlParams.get('cm_mmc');

        if (trackingId) {
          this.trackingId = trackingId;
          sessionStorage.setItem('cobrand.trackingId', trackingId);
        } else if (paidMediaTrackingId) {
          this.trackingId = paidMediaTrackingId;
          sessionStorage.setItem('cobrand.trackingId', paidMediaTrackingId);
        }
      }
    }
  },
  async mounted() {
    if (typeof window !== 'undefined') {
      this.setScreenWidth();
      this.setScrollHandler();
      window.addEventListener('resize', this.setScreenWidth);
      window.addEventListener('scroll', this.scrollHandler);
    }
    /**
     * Get referer from additionalData if available and set it to session with key cobrand.referer
     */
    if (typeof window !== 'undefined' && document.referrer && !!this.additionalData?.allowedRefererList) {
      const { hostname } = new URL(document.referrer);
      if (this.additionalData
        ?.allowedRefererList
        ?.some((domain) => hostname?.toLowerCase().endsWith(domain))) {
        sessionStorage.setItem('cobrand.referer', document.referrer);
      }
    }

    if (typeof window !== 'undefined' && document.referrer && document.referrer.toLowerCase().indexOf('rei.com') === -1) {
      sessionStorage.setItem('cobrand.trackingReferer', document.referrer);
    }

    // MEM-2960: Side By Side Benefits Test
    try {
      const response = await AdobeTarget.getAll();

      if (response?.length) {
        const sideBySideTest = response.find(({ content }) => content?.experimentName?.includes('MEM-2960'));

        if (sideBySideTest) {
          this.sideBySideTestVariant = sideBySideTest?.content?.experience;
        }
      }
    } catch (e) {
      console.error(e);
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setScreenWidth);
    window.removeEventListener('scroll', this.scrollHandler);
  },
  methods: {
    setScreenWidth() {
      const { innerWidth = 0 } = window;
      this.screenWidth = innerWidth;
    },
    setScrollHandler() {
      this.scrollHandler = buildStickyCtaScrollHandler(
        (showCta) => {
          this.showStickyCta = showCta;
        },
        'lead-cta-button',
        'giftcard-promo-button'
      );
    },
    async getPersonalizedTopCategory() {
      try {
        const response = await getPersonalizedMemberRewardInfo();

        this.personalizedTopCategory = response?.topCategory;
      } catch (error) {
        console.error(error);
      }
    },
    async getIsReiCardHolder() {
      try {
        const body = this.isCAASEnabled ? await getCustomerAttributes() : {
          member: false,
          reiccholder: false,
        };
        this.isCardHolder = body?.reiccholder;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">

.mastercard {
  @include cdr-container;
  margin-top: $cdr-space-two-x;
  margin-bottom: $cdr-space-two-x;
  display: grid;
  grid-row-gap: $cdr-space-two-x;
  @include cdr-md-mq-up {
    margin-top: $cdr-space-three-x;
    grid-row-gap: $cdr-space-three-x;
  }
}

.mastercard__lower {
  background-color: $cdr-color-background-secondary;
  margin-top:  $cdr-space-three-x;
  @include cdr-md-mq-up {
    margin-top: $cdr-space-four-x;
  }
}

.mastercard__lower-inner {
  @include cdr-container;
}

.mastercard__rwd-calc {
  background-color: $cdr-color-background-primary;
}

.mastercard__rwd-calc-inner {
  @include cdr-container;
  padding: $cdr-space-one-and-a-half-x;
  @include cdr-md-mq-up {
    padding-top: 8rem; // idk
    padding-bottom: 0;
  }
}
</style>
